<template>
    <div class="paymentOrder">
        <div class="title">合同备案</div>
        <div class="headBox">
            <div class="right">
                <el-input v-model="customerName" placeholder="输入名称" size="mini" @blur="getData"></el-input>
                <el-button type="primary" size="mini">搜索</el-button>
            </div>
        </div>
        <table-list
            :ButtonWidth="180"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            :selection="$route.query.recovery == 0"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                <div v-if="scope.colr.prop == 'images' && scope.row['contractImageList'].length > 0" class="images">
                    <div v-for="(item, index) in scope.row['contractImageList']" class="imgItem">
                        <el-image
                            style="width: 80px; height: 80px"
                            :src="'//safepayment.com.cn:18080/attachment' + item.imagePath"
                            :preview-src-list="['//safepayment.com.cn:18080/attachment' + item.imagePath]"
                        >
                        </el-image>
                        <div class="xzBtn" @click.stop="downImg(item)">下载</div>
                    </div>
                </div>
            </template>
            <template #Button="scope">
                <el-button size="mini" type="text" @click="check(scope.row)">查看</el-button>
            </template>
        </table-list>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import contractBackupsApi from "../../api/contractBackupsApi";
import axios from "axios";
export default {
    name: "paymentOrder",
    components: { TableList },
    data() {
        return {
            tableData: [],
            colrs: [
                { label: "签约单号", prop: "id", width: "300px" },
                { label: "客户姓名", prop: "customerName", width: "300px" },
                { label: "合同备份", prop: "images", width: "300px" },
                { label: "", prop: "", width: "" }
            ],
            total: 0,
            pagesize: 20,
            pageIndex: 1,
            form: {},
            search: "",
            token: this.$utils.storage.get("satoken"),
            customerName: ""
        };
    },
    mounted() {
        document.title = "代扣系统-合同备案";
        this.getData();
    },
    methods: {
        getData() {
            contractBackupsApi
                .getPageList({
                    pageSize: this.pagesize,
                    pageNumber: this.pageIndex,
                    customerName: this.customerName
                })
                .then(res => {
                    if (res.status == 200) {
                        this.tableData = res.data.records;
                        this.total = res.data.total;
                    }
                });
        },

        check(row) {
            this.$router.push({ name: "/contractBackups/orderDetails", query: { id: row.id } });
        },

        downImg(item) {
            const satoken = this.$utils.storage.get("satoken");
            const id = item.id;
            const imageUrl = `//safepayment.com.cn:18080/contractFiling/downloadImage/${id}?satoken=${satoken}`;

            const link = document.createElement("a");
            link.href = imageUrl;
            link.setAttribute("download", "image.jpg"); // 设置下载文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        currentChange(pageIndex) {
            this.pageIndex = pageIndex - 1;
        },
        handleSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pagesize = pageSize;
            this.getData();
        }
    }
};
</script>
<style lang="scss" scoped>
.paymentOrder {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    min-height: 85vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .headBox {
        display: flex;
        justify-content: right;
        align-items: center;
        margin-bottom: 15px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
        }
    }

    .elTag {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 320px;
        .elTagSpan {
            display: flex;
            align-items: center;
            img {
                width: 12px;
                height: 12px;
                margin-right: 10px;
            }
        }
    }

    /deep/.el-table .cell {
        line-height: normal !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    /deep/ .el-button--text {
        height: 16px;
        padding: 0;
    }

    .infoItem {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
        .imgItem {
            width: 120px;
            height: 120px;
            border: 1px dashed #d9d9d9;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .images {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .imgItem {
            width: 80px;
            height: 80px;
            background: #ccc;
            cursor: pointer;
            position: relative;
            &:last-child {
                margin-bottom: 0px;
            }
            .xzBtn {
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba($color: #000000, $alpha: 0.5);
                color: #fff;
                width: 100%;
                text-align: center;
            }
            .xzBtn:hover {
                color: #409eff;
            }
        }
    }
}
</style>
<style>
.v-modal {
    z-index: auto !important;
}
</style>